import trailer from "./trailer.mp4";
import "./App.css";
import hit from "./hit.png";
import { Button, Link, makeStyles, useMediaQuery } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import googlestore from "./googlestore1.svg";
import apple from "./apple1.svg";
import internet from "./internet2.svg";
import github from "./github1.svg";
import waves from "./waves.svg";

const useStyles = makeStyles({
  showcase: {
    width: "100%",
    height: "85vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#111",
    color: "#fff",
    zIndex: 2,
  },
  showcaseHeader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    padding: "32px 32px",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  showcaseVideo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: 0.2,
  },
  logo: {
    fontSize: "16px",
  },
  toggle: {},
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "85vh",
    background: "#00000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {},
  content: {
    width: "100%",
    backgroundColor: "#181818",
    paddingTop: 88,
    paddingBottom: 16,
  },
  footer: {
    height: 70.0,
    // backgroundColor: "#181818",
    backgroundColor: "#4FACF7",
    color: "white",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    paddingLeft: 32.0,
  },
  icon: {
    marginRight: 8.0,
  },
});

function App() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:700px)");
  console.log({ matches });
  return (
    <div className="App">
      <div className={classes.showcase}>
        <header className={classes.showcaseHeader}>
          <h2 className={classes.logo}>MOHAMED WRITES CODE</h2>
        </header>

        <video
          className={classes.showcaseVideo}
          src={trailer}
          muted
          loop
          autoPlay
        ></video>

        <div className={classes.overlay}>
          <div style={{ maxWidth: 600 }}>
            <h2 style={{ fontSize: "42px", textAlign: "center" }}>
              Programming Videos, Tutorials, and Apps
            </h2>
            <p style={{ marginTop: 8.0 }}>By Mohamed Ibrahim</p>
            <Button
              style={{
                marginTop: 16.0,
                backgroundColor: "#1389FD",
                padding: 0,
              }}
              variant="contained"
              color="primary"
            >
              <Link
                href="#content"
                style={{
                  textDecoration: "none",
                  color: "white",
                  padding: "6px 16px",
                }}
              >
                Explore
              </Link>
            </Button>
          </div>
        </div>
      </div>

      <div className={classes.content} id="content">
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "0px 32px",
              maxWidth: 550,
              color: "white",
              textAlign: "left",
            }}
          >
            <h2>Cross-Platform Development with Flutter Course</h2>
            <p style={{ color: "#B3BDD9", marginTop: 8.0, lineHeight: 1.2 }}>
              Learn to build apps that work on Android, iOS, Web, and Desktop
            </p>
            <p style={{ marginTop: 16.0, marginBottom: 16.0, lineHeight: 1.2 }}>
              In this course, we will dive into Flutter development by building
              5 applications from scratch. We will cover building great user
              interfaces, navigation, persistent storage, network requests (API
              calls), managing state, integration with Firebase, and all the
              concepts you need to start bringing your ideas to life.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: 32.0,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#1389FD",
                  padding: 0,
                }}
                variant="contained"
                color="primary"
              >
                <Link
                  href="https://www.youtube.com/watch?v=FWPmfBmkXv8&list=PLSpXkMCnT_uY2tcpylu2DsVzpwei8gpIT&index=1&ab_channel=MohamedIbrahim"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    padding: "6px 16px",
                  }}
                >
                  GO TO COURSE
                </Link>
              </Button>
              <Link
                href="https://github.com/mibrah42/Cross-Platform-Development-with-Flutter-Course"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "white",
                  marginLeft: 16,
                }}
              >
                <ReactSVG src={github} />
              </Link>
            </div>
          </div>
          <iframe
            style={{ width: "90%", maxWidth: 600, height: 350 }}
            src="https://www.youtube.com/embed/videoseries?list=PLSpXkMCnT_uY2tcpylu2DsVzpwei8gpIT"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      {/* <div className={classes.content}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "0px 32px",
              maxWidth: 550,
              color: "white",
              textAlign: "left",
            }}
          >
            <h2>Introduction to Programming with Python</h2>
            <p style={{ color: "#B3BDD9", marginTop: 8.0, lineHeight: 1.2 }}>
              Learn language fundamentals and web development with Flask
            </p>
            <p style={{ marginTop: 16.0, marginBottom: 16.0, lineHeight: 1.2 }}>
              In this workshop series, you will be introduced to programming
              with Python. You will cover the basics of the language, web
              development using flask, deploying your web apps with Heroku, and
              graph traversal algorithms including Depth First Search (DFS) and
              Breadth First Search (BFS).
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: 32.0,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#1389FD",
                  padding: 0,
                }}
                variant="contained"
                color="primary"
              >
                <Link
                  href="https://www.youtube.com/watch?v=ByvWZTKH5pY&list=PLSpXkMCnT_uZIJHEHTRTa6Gu3lJz6NuDK&index=1&ab_channel=MohamedIbrahim"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    padding: "6px 16px",
                  }}
                >
                  GO TO COURSE
                </Link>
              </Button>
              <Link
                href="https://github.com/mibrah42/programming_workshops"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "white",
                  marginLeft: 16,
                }}
              >
                <ReactSVG src={github} />
              </Link>
            </div>
          </div>
          <iframe
            style={{ width: "90%", maxWidth: 600, height: 350 }}
            src="https://www.youtube.com/embed/videoseries?list=PLSpXkMCnT_uZIJHEHTRTa6Gu3lJz6NuDK"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div> */}

      <div className={classes.content} style={{ paddingBottom: 0 }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "0px 32px",
              maxWidth: 550,
              color: "white",
              textAlign: "left",
            }}
          >
            <h2>Humans in Tech</h2>
            <p style={{ color: "#B3BDD9", marginTop: 8.0, lineHeight: 1.2 }}>
              A casual networking platform designed to spark spontaneous and
              inspiring conversations among people in tech
            </p>
            <p style={{ marginTop: 16.0, marginBottom: 16.0, lineHeight: 1.2 }}>
              Humans in Tech takes a casual approach to professional networking
              and makes 1-on-1 chats with inspiring individuals easier than
              ever. You can chat with any member to ask for career advice,
              brainstorm ideas, or discuss professional/personal interests.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: 32.0,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#1389FD",

                  padding: 0,
                }}
                variant="contained"
              >
                <Link
                  href="https://www.humansintech.io"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    padding: "6px 16px",
                  }}
                >
                  VISIT LANDING PAGE
                </Link>
              </Button>
              <Link
                href="https://play.google.com/store/apps/details?id=io.humansintech.humansintech"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "white",
                  marginLeft: 16,
                }}
              >
                <ReactSVG src={googlestore} />
              </Link>
              <Link
                href="https://apps.apple.com/ca/app/humans-in-tech/id1576796616"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "white",
                  marginLeft: 8,
                }}
              >
                <ReactSVG src={apple} />
              </Link>
              <Link
                href="https://app.humansintech.io/"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "white",
                  marginLeft: 8,
                }}
              >
                <ReactSVG src={internet} />
              </Link>
            </div>
          </div>

          <div
            style={{
              width: "90%",
              maxWidth: 600,
              height: 350,
              borderRadius: 16.0,
              backgroundImage: `url(${hit})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      </div>

      {/* <div
        style={{
          height: "0.3px",
          backgroundColor: "#B3BDD9",
          opacity: 0.1,
          width: "100%",
          marginTop: 16.0,
        }}
      /> */}
      <div
        style={{
          aspectRatio: "960/300",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${waves})`,
          marginTop: matches ? "-120px" : "0px",
        }}
      ></div>
      <section className={classes.footer}></section>
    </div>
  );
}

export default App;
